<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="edit" name="plantCd" v-model="searchParam.plantCd" @input="getCourse"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="courseItems"
            type="search"
            itemText="educationCourseName"
            itemValue="eduCourseId"
            name="eduCourseId"
            label="교육과정"
            v-model="searchParam.eduCourseId"
          >
          </c-select>
        </div>
        <div v-if="popupParam.exceptDocumentStatusCd" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            type="search"
            codeGroupCd="EDU_STATUS_CD"
            itemText="codeName"
            itemValue="code"
            name="documentStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.documentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="search"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            type="search"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육기간 -->
          <c-datepicker
            :range="true"
            label="교육기간"
            name="period"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="교육계획및결과 목록"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      rowKey="eduEducationId"
      checkDisableColumn="non"
      :selection="popupParam.type"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "educationPop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          /**
           * 진행상태 제외할 옵션
           * 해당 props에 값이 있는 경우 검색조건 진행상태 사용X
           */
          exceptDocumentStatusCd: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        documentStatusCd: null,
        startYmd: '',
        endYmd: '',
        eduCourseId: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        exceptDocumentStatusCd: null,
        period: [],
      },
      grid: {
        columns: [
          {
            fix: true,
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            // style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            fix: true,
            name: "educationName",
            field: "educationName",
            label: "교육명",
            // style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            fix: true,
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            // style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            fix: true,
            name: "educationDate",
            field: "educationDate",
            label: "교육일",
            // style: 'width:200px',
            align: "center",
            sortable: true,
          },
          {
            name: "eduPlanTime",
            field: "eduPlanTime",
            label: "교육시간",
            // style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            // style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            // style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            align: "center",
            sortable: true,
          },
          {
            name: "documentStatusName",
            field: "documentStatusName",
            label: "LBLPROGRESS",
            // style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            // style: 'width:90px',
            label: "교육방법",
            align: "center",
            sortable: true,
          },
          // {
          //   name: "educationPurpose",
          //   field: "educationPurpose",
          //   style: 'width:200px',
          //   label: "학습목적",
          //   align: "left",
          //   sortable: true,
          // },
          // {
          //   name: "relatedLawsName",
          //   field: "relatedLawsName",
          //   style: 'width:200px',
          //   label: "관련법규",
          //   align: "left",
          //   sortable: true,
          // },
          // {
          //   name: "eduQuestionFlag",
          //   field: "eduQuestionFlag",
          //   label: "문제풀이",
          //   style: 'width:80px',
          //   align: "center",
          //   sortable: true,
          // },
        ],
        data: [],
        height: '600px'
      },
      courseItems: [],
      editable: true,
      listUrl: "",
      courseUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isCourse() {
      return Boolean(this.searchParam.eduCourseId)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.result.list.url;
      this.courseUrl = selectConfig.sop.edu.course.list.url;
      // code setting
      this.searchParam.period = [this.$comm.getFirstDayThisYear(), this.$comm.getLastDayThisYear()];
      if (this.popupParam.exceptDocumentStatusCd) {
        this.$set(this.searchParam, 'exceptDocumentStatusCd', this.popupParam.exceptDocumentStatusCd)
      }
      // list setting
      this.getCourse();
      this.getList();
    },
    getCourse() {
      this.searchParam.eduCourseId = null;
      if (this.searchParam.plantCd) {
        this.$http.url = this.courseUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.searchParam.plantCd, 
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.courseItems = _result.data;
        },);
      } else {
        this.courseItems = [];
      }
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }

      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "교육계획 및 결과 상세"; // 교육계획 상세
      this.popupOptions.param = {
        plantCd: row.plantCd ? row.plantCd : this.searchParam.plantCd,
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () => import(`${"./educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '교육을 지정하세요.', // 교육을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
